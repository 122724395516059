.t {
  transform: none;
}
[sns-share] {
  position: relative;
  cursor: pointer;
  z-index: 1;
}
[sns-share] a.btn {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.no-touch [sns-share] a.btn:hover {
  opacity: 0.5;
}
[sns-share] .share-popup {
  position: absolute;
  width: 10.625rem;
  transform: scale(0);
  border-radius: 0.75rem;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.54, -0.03, 0.06, 0.98);
}
[sns-share] .share-popup ul {
  padding: 1.25rem;
}
[sns-share] .share-popup ul li {
  opacity: 0;
  aspect-ratio: inherit !important;
  transition-delay: 0s;
  overflow: hidden;
  transform: translateY(10px);
  transition: opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s, transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;
}
[sns-share] .share-popup ul li a {
  display: block;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: #888;
  background-color: transparent;
  white-space: nowrap;
  text-decoration: none;
}
[sns-share] .share-popup ul li a img {
  display: inline-block;
  width: 2.375rem;
  height: 2.375rem;
  margin-right: 0.75rem;
  vertical-align: middle;
}
[sns-share] .share-popup ul li #shareUrl {
  position: absolute;
  left: -625rem;
  top: 0rem;
  right: auto;
  bottom: auto;
}
[sns-share] .share-popup ul li + li {
  margin-top: 0.625rem;
}
[sns-share].on .share-popup {
  transform: scale(1);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}
[sns-share].on .share-popup ul li {
  opacity: 1;
  transform: translateY(0px);
}
[sns-share].on .share-popup li:nth-of-type(1) {
  transition-delay: 0.2s;
}
[sns-share].on .share-popup li:nth-of-type(2) {
  transition-delay: 0.3s;
}
[sns-share].on .share-popup li:nth-of-type(3) {
  transition-delay: 0.4s;
}
[sns-share].on .share-popup li:nth-of-type(4) {
  transition-delay: 0.5s;
}
[sns-share].on .share-popup li:nth-of-type(5) {
  transition-delay: 0.6s;
}
[sns-share].on .share-popup li:nth-of-type(6) {
  transition-delay: 0.7s;
}
[sns-share].play-main .share-popup {
  right: 0rem;
  top: 1.5625rem;
  left: auto;
  bottom: auto;
  background-color: #333;
  transform-origin: right top;
}
[sns-share].play-main .share-popup ul li a {
  color: #ddd;
}
[sns-share].news-article .share-popup {
  right: -11.375rem;
  top: 0rem;
  left: auto;
  bottom: auto;
  border: 1px solid #ebebeb;
  transform-origin: left top;
}
