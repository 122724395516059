.t {
  transform: none;
}
[cdn-video] {
  display: block;
}
[cdn-video].cover {
  object-fit: cover;
}
[cdn-video].contain {
  object-fit: contain;
}
